<template>
    <base-page>
        <titlu-pagina Titlu="Variabile" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">

            </div>
        </el-card>

        <el-table :data="Variabile">
            <el-table-column prop='Nume' label='Nume Variabila'></el-table-column>
            <el-table-column prop='Html' label='Html'></el-table-column>
            
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <el-dialog title="Adauga Variabila" :visible.sync="showDialogAddVariabila" class="my-dialog-class" >
            <el-form label-position="top" :inline="false" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
                <el-row :gutter="15" >
                    <el-col :md='8'>
                        <el-form-item label='Nume' prop="Nume" >
                            <el-input class='full-width' v-model='selectedObject.Nume' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='8'>
                        <el-form-item label='Html' >
                            <el-input class='full-width' v-model='selectedObject.Html' />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer" >
                <el-button @click="showDialogAddVariabila=false"> Renunta  </el-button>
                <el-button type="primary" @click="save"> Salveaza </el-button>
            </span>
        </el-dialog>
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "variabile",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
    },
    data () {
        return {
            Variabile: [],
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            base_url: '',
            loadingVisible: false,
            selectedObject: { Nume: '', Label: '', Html: '' },
            showDialogAddVariabila: false
        }
    },
    methods: {
        async refresh_info(){  
            var response        = await this.post("variabile/index", { PaginationInfo: this.PaginationInfo } );
            this.Variabile      = response.Variabile;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('variabile');
        },
        async show_dialog(){
            this.showDialogAddVariabila = true;
        },
        async save(){
            console.log(this.selectedObject);
            let res = await this.post('variabile/save', {
                object: this.selectedObject
            })
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.refresh_info();
    }
};
</script>

<style lang="less" >

    .top50{
        margin-top: 20px;
    }
    .proper{
        margin-top: 20px;
        text-transform: 'capitalize';
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
    .el-table .danger-row {
        background: #f05b6c;
    }

</style>
